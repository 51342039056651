import React from 'react';
import Metadata from '../components/Metadata';
import Section from '../components/Section';
import styled from 'styled-components';
import ShowMoreButton from '../components/ShowMoreButton';
import { StaticImage } from 'gatsby-plugin-image';
import { StyledContainer } from '../layouts/styles';

const StyledImageTeaser = styled.div`
  display: flex;
  flex-direction: column-reverse;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 2rem;
    font-size: 1.5rem;
  }

  @media (min-width: 920px) {
    margin-bottom: 1rem;
    display: grid;
    grid-gap: 5rem;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledImageContainer = styled.div`
  margin-bottom: 2rem;

  @media (min-width: 920px) {
    margin-bottom: 0;
    order: ${({ reverse }) => (reverse ? '-1' : 'revert')};
  }
`;

function Team() {
  return (
    <>
      <Metadata title='Team' />
      <StyledContainer>
        <h1>Team</h1>
        <Section>
          <h3>Unsere Haltung</h3>
          <p>
            Jeder Mensch kann sein volles Potential entfalten, wenn dafür die
            richtigen Bedingungen geschaffen werden. Da die richtigen
            Bedingungen nicht dasselbe für jeden Menschen bedeuten, machen wir
            es uns zur Aufgabe, diese gemeinsam zu erarbeiten.
          </p>
          <p>
            Es ist uns wichtig, nicht nur durch Fachlichkeit und professionelles
            Handeln zu überzeugen, sondern gemeinsame Ziele auf einer
            wertschätzenden Beziehung aufzubauen. Wir möchten die individuellen
            Eigenschaften jeder einzelnen Person wahrnehmen und dabei
            voneinander und miteinander lernen.
          </p>
          <h3>Unsere Qualifikationen</h3>
          <p>
            Jede Fachkraft hat ein einschlägiges Studium aus einem sozialen
            pädagogischen Bereich absolviert, sich zum Themenfeld Autismus
            weitergebildet sowie eine umfangreiche praktische und theoretische
            Einarbeitung in die methodische Umsetzung erhalten.{' '}
          </p>
          <p>
            In unserem Team kommen somit verschiedene Perspektiven aus
            unterschiedlichen Fachbereichen zusammen. Hierzu gehören unter
            anderem die angewandte Verhaltensanalyse, Psychologie, Pädagogik,
            Ergotherapie, Sprachtherapie und die soziale Arbeit. Dies ermöglicht
            uns einen regelmäßigen Austausch über Therapieansätze dieser
            wissenschaftlichen Disziplinen, die wir in unsere Arbeit mit dem
            Ziel einfließen lassen, jeder Person die individuell passendste und
            effektivste Förderung zu bieten. Eine permanente Weiterbildung ist
            uns dabei ein großes Anliegen, um stets die neuesten
            Forschungsergebnisse zu berücksichtigen.{' '}
          </p>
          <p>
            Therapeuten und Therapeutinnen spezialisieren sich bei uns auf die
            unterschiedlichen Altersgruppen und Förderbedarfe, so dass wir den
            verschiedenen Anforderungen in unserer Arbeit gerecht werden können.
          </p>
        </Section>

        <Section>
          <StyledImageTeaser>
            <div>
              <h2>Gründerin & Leitung</h2>
              <p>
                Erfahren Sie mehr über die Gründerin & Leitung
                <br />
              </p>
              <ShowMoreButton to='/leitung'>Mehr erfahren</ShowMoreButton>
            </div>
            <StyledImageContainer>
              <StaticImage
                src='../images/sabrina_liebich.jpg'
                alt='Bild von Sabrina Liebich'
              ></StaticImage>
            </StyledImageContainer>
          </StyledImageTeaser>
        </Section>

        <Section>
          <StyledImageTeaser>
            <div>
              <h2>Jobs</h2>
              <p>
                Wir freuen uns auf deine Initiativbewerbung
                <br />
              </p>
              <ShowMoreButton to='/jobs'>Mehr erfahren</ShowMoreButton>
            </div>
            <StyledImageContainer reverse>
              <StaticImage
                src='../images/Jobs_Teaser.png'
                alt='Hände zusammen'
              ></StaticImage>
            </StyledImageContainer>
          </StyledImageTeaser>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Team;
